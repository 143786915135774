<template>
  <div>
    <NavBar class="fixed top-0 left-0 right-0"></NavBar>

    <secondary-navbar title="Wine Information" :showbroker="false" :showaction="false" :showback="true" />

    <div class="w-full mt-6 grid grid-cols-10 hidden sm:grid" id="page-login">
      <div class="col-span-2 sm:block 0 background-f4">
        <left-side-strip :showBrokerButton="true" />
      </div>

      <div class="col-span-8">

            <div v-if="$store.state.portfolio" class="m-20">


              <div v-for="investment in $store.state.portfolio" v-bind:key="investment.id">
                <div v-for="item in investment.items" v-bind:key="item.id">

                  <div v-if="(item.wine_id == $route.params.id && investment.id == $route.params.orderId)" class="">
                    <!-- {{$store.state.portfolio}} -->

                    <div v-for="total in getTotals" v-bind:key="total.currency" class="">
                      <div class=" float-right">
                        <span class="oeno_portfolio_total_total float-right ">{{ currencyFormatter(getPrice(investment,
                            item))
                        }} {{ investment.currency }}</span>
                        <br />
                        <span :class="[
                          priceUp(investment, item)
                            ? 'oeno_portfolio_price_up float-right'
                            : 'oeno_portfolio_price_down float-right',
                        ]">{{ getChange(investment, item, true) }}</span>
                      </div>
                    </div>

                    <wine-detail :item="item" :investment="investment" />

                  </div>
                </div>
              </div>
            </div>


          </div>

    </div>
    <Footer></Footer>
  </div>

</template>

<script>
import moment from "moment";
import NavBar from "@/components/layout/navbar.vue";
import SecondaryNavbar from "@/components/layout/SecondaryNavbar.vue";
import Footer from "@/components/layout/footer.vue";
import LeftSideStrip from "@/components/pageparts/LeftSideStrip.vue";
import WineDetail from "../../../components/wine/WineDetail.vue"

export default {
  data() {

    return {
      getTotalPrice: "",
      getTotalPriceDiff: ""
    };
  },
  components: {
    NavBar,
    Footer,
    LeftSideStrip,
    SecondaryNavbar,
    WineDetail,
  },
  mounted() {

  },
  computed: {

    getTotals() {
      const values = [];

      this.$store.state.portfolio.forEach((investment) => {
        investment.items.forEach((item) => {
          var itemPrice = this.getValuesForItem(investment, item);

          var pos = -1;
          for (let index = 0; index < values.length; index++) {
            if (values[index].currency == itemPrice.currency) {
              pos = index;
            }

          }

          if (pos > -1) {

            values[pos].total = values[pos].total + parseFloat(itemPrice.total);
            values[pos].changePrice = values[pos].changePrice + parseFloat(itemPrice.changePrice);

          } else {

            values.push({
              currency: itemPrice.currency,
              changePrice: parseFloat(itemPrice.changePrice),
              total: itemPrice.total,
            });

          }

        });
      });
      return values;
    },
  },
  methods: {


    getValuesForItem(investment, wine) {
      var isInLastSixMonths = "N";
      var orderDate = moment(investment.order_date);
      var sixMonthsAgo = moment().subtract(6, "months");
      if (sixMonthsAgo < orderDate) {
        isInLastSixMonths = "Y";
      }

      var total =
        parseInt(
          parseFloat(
            wine.quantity *
            wine.wines.units_per_case *
            wine.livePrices.data["price-average"]
          ) * 100
        ) / 100;

      var buytotal =
        wine.quantity * wine.wines.units_per_case * wine.bottle_price;

      if (isInLastSixMonths == "Y") {
        if (total < buytotal) {
          total = buytotal;
        }
      }

      var changePerc = ((parseFloat(total) - parseFloat(buytotal)) / parseFloat(buytotal)) * 100;

      var changePrice = parseFloat(total) - parseFloat(buytotal);

      return {
        changePerc: changePerc,
        changePrice: changePrice,
        total: total,
        currency: investment.currency,
      };
    },

    getPrice(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);
      this.getTotalPrice = priceValues.total;
      return this.getTotalPrice;
    },

    getChange(investment, wine, showperc = false) {
      var priceValues = this.getValuesForItem(investment, wine);
      if (showperc) {
        return this.currencyFormatter(priceValues.changePrice) + " ( " + this.currencyFormatter(priceValues.changePerc) + " %)";
      } else {
        return this.currencyFormatter(priceValues.changePrice);
      }
    },

    priceUp(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      if (priceValues.changePrice >= 0) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
